<template>
  <div class="myOrderBox">
    <div class="seleteBox">
      <el-tabs v-model="activeName" @tab-click="handleClick">
        <el-tab-pane label="我的订单" name="order" lazy></el-tab-pane>
        <el-tab-pane label="我的解锁" name="unlock" lazy></el-tab-pane>
      </el-tabs>
      <div class="inputBox" v-if="activeName == 'order'">
        <el-select
          v-model="paramsData.fid"
          placeholder="请选择"
          class="selectType"
          @change="restorePage"
        >
          <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
        <el-date-picker
          @change="restorePage"
          style="width: 3rem"
          v-model="paramsData.dateTime"
          type="daterange"
          align="right"
          unlink-panels
          range-separator="-"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          :picker-options="pickerOptions"
        >
        </el-date-picker>
      </div>
    </div>
    <div class="orderListBox" v-if="activeName == 'order'">
      <div class="orderList" v-if="listTotal > 0">
        <div class="orderItem" v-for="(item, index) in reCodeList" :key="index">
          <div class="itemHead">
            <div style="width: 5.2rem; padding-left: 0.2rem" class="df_Yc">
              <p class="mr40 f0404 f12">{{ item.addtime }}</p>
              <p v-if="paramsData.fid === '6'" class="f12 f0404">类型：课程</p>
              <p v-if="paramsData.fid === '7'" class="f12 f0404">类型：素材</p>
              <p v-if="paramsData.fid === '1393'" class="f12 f0404">类型：3D模型</p>
              <p v-if="paramsData.fid === '1394'" class="f12 f0404">类型：方案文本</p>
              <p v-if="paramsData.fid === '561'" class="f12 f0404">类型：SU模型</p>
              <p v-if="paramsData.fid === '1196'" class="f12 f0404">类型：PS素材</p>
              <p v-if="paramsData.fid === '2205'" class="f12 f0404">类型：贴图</p>
            </div>
            <div style="width: 1.5rem" class="t_c f12 f0404">价格</div>
            <div style="width: 1.5rem" class="t_c f12 f0404">实付金额</div>
            <div style="flex: 1" class="t_c f12 f0404">操作</div>
          </div>
          <div class="itemInfo">
            <div
              class="df_Yc"
              style="width: 5rem; border-right: 1px solid #dfdffe; height: 100%"
            >
              <div class="itemImgCourse" v-if="paramsData.fid === '6'">
                <router-link
                  target="_blank"
                  :to="{
                    path: '/course/detail',
                    query: { detailId: item.pid },
                  }"
                >
                  <img :src="item.img" alt="" />
                </router-link>
              </div>
              <div class="itemImgMaterial" v-if="paramsData.fid === '7'">
                <router-link
                  target="_blank"
                  :to="{
                    path: '/material/detail',
                    query: { detailId: item.pid },
                  }"
                >
                  <img :src="item.img" alt="" />
                </router-link>
              </div>
              <div class="itemImgSu" v-if="paramsData.fid === '561'">
                <router-link
                  target="_blank"
                  :to="{ path: '/su/detail', query: { detailId: item.pid } }"
                >
                  <img :src="item.img" alt="" />
                </router-link>
              </div>
              <div class="itemImgSu" v-if="paramsData.fid === '1393'">
                <router-link
                    target="_blank"
                    :to="{ path: '/max/detail', query: { detailId: item.pid } }"
                >
                  <img :src="item.img" alt="" />
                </router-link>
              </div>
              <div class="itemImgSu" v-if="paramsData.fid === '1394'">
                <router-link
                    target="_blank"
                    :to="{ path: '/schemeText/detail', query: { detailId: item.pid } }"
                >
                  <img :src="item.img" alt="" />
                </router-link>
              </div>
              <div class="itemImgSu" v-if="paramsData.fid === '1196'">
                <router-link
                  target="_blank"
                  :to="{ path: '/ps/detail', query: { detailId: item.pid } }"
                >
                  <img :src="item.img" alt="" />
                </router-link>
              </div>
              <div class="itemImgSu" v-if="paramsData.fid === '2205'">
                <router-link
                  target="_blank"
                  :to="{ path: '/chartlet/detail', query: { detailId: item.pid } }"
                >
                  <img style="object-fit: cover;" :src="item.img" alt="" />
                </router-link>
              </div>
              <p class="itemP">{{ item.title }}</p>
            </div>
            <p
              style="width: 1.5rem; height: 100%; line-height: 1.2rem"
              class="f14 t_c c_33 br"
            >
              {{ item.work_price }}知币
            </p>
            <p
              style="width: 1.5rem; height: 100%; line-height: 1.2rem"
              class="f14 t_c c_33 br"
            >
              {{ item.money }}知币
            </p>
            <div style="flex: 1" v-if="paramsData.fid === '6' && !item.comment">
              <div class="itemBut" @click="comment(item)">评论</div>
            </div>
            <div style="flex: 1" v-if="paramsData.fid === '6' && item.comment">
              <div class="itemBut" @click="comment(item)">查看评论</div>
            </div>
            <div style="flex: 1" v-if="paramsData.fid !== '6' && paramsData.fid !== '2205'">
              <div class="itemBut" @click="download(item)">下载</div>
            </div>
            <div style="flex: 1" v-if="paramsData.fid == '2205'">
							<div class="itemBut" @click="downloadChartlet(item)">下载</div>
            </div>
          </div>
        </div>
      </div>
      <div v-else>
        <el-empty description="没有内容哦"></el-empty>
      </div>
    </div>
    <div class="orderListBox" v-if="activeName == 'unlock'">
      <div class="orderList1" v-if="listTotal > 0">
        <div
          class="orderItem1"
          v-for="(item, index) in reCodeList1"
          :key="index"
        >
          <div class="itemHead">
            <div style="width: 5.2rem; padding-left: 0.2rem" class="df_Yc">
              <p v-if="item.cate === '课程'" class="f12 f0404">类型：课程</p>
              <p v-if="item.cate === '素材'" class="f12 f0404">类型：素材</p>
              <p v-if="item.cate === '3D模型'" class="f12 f0404">类型：3D模型</p>
              <p v-if="item.cate === '方案文本'" class="f12 f0404">类型：方案文本</p>
              <p v-if="item.cate === 'SU模型'" class="f12 f0404">
                类型：SU模型
              </p>
              <p v-if="item.cate === 'PS素材'" class="f12 f0404">
                类型：PS素材
              </p>
            </div>
          </div>
          <div class="itemInfo">
            <div class="df_Yc">
              <div class="itemImgCourse" v-if="item.cate === '课程'">
                <router-link
                  target="_blank"
                  :to="{
                    path: '/course/detail',
                    query: { detailId: item.id },
                  }"
                >
                  <img :src="item.img" alt="" />
                </router-link>
              </div>
              <div class="itemImgMaterial" v-if="item.cate === '素材'">
                <router-link
                  target="_blank"
                  :to="{
                    path: '/material/detail',
                    query: { detailId: item.id },
                  }"
                >
                  <img :src="item.img" alt="" />
                </router-link>
              </div>
              <div class="itemImgSu" v-if="item.cate === 'SU模型'">
                <router-link
                  target="_blank"
                  :to="{ path: '/su/detail', query: { detailId: item.id } }"
                >
                  <img :src="item.img" alt="" />
                </router-link>
              </div>
              <div class="itemImgSu" v-if="item.cate === '3D模型'">
                <router-link
                    target="_blank"
                    :to="{ path: '/max/detail', query: { detailId: item.id } }"
                >
                  <img :src="item.img" alt="" />
                </router-link>
              </div>
              <div class="itemImgSu" v-if="item.cate === '方案文本'">
                <router-link
                    target="_blank"
                    :to="{ path: '/schemeText/detail', query: { detailId: item.id } }"
                >
                  <img :src="item.img" alt="" />
                </router-link>
              </div>
              <div class="itemImgSu" v-if="item.cate === 'PS素材'">
                <router-link
                  target="_blank"
                  :to="{ path: '/ps/detail', query: { detailId: item.id } }"
                >
                  <img :src="item.img" alt="" />
                </router-link>
              </div>
              <p class="itemP1">{{ item.title }}</p>
            </div>
          </div>
        </div>
      </div>
      <div v-else>
        <el-empty description="没有内容哦"></el-empty>
      </div>
    </div>
    <div class="t_c">
      <el-pagination
        v-show="listTotal > 0"
        background
        layout="prev, pager, next"
        @current-change="setPage"
        :total="listTotal"
        :page-size="activeName == 'order' ? 10 : 8"
      ></el-pagination>
    </div>
  </div>
</template>

<script>
import { getToken } from "@/utils/auth";
import { admireList, disCountList } from "../../Api";

export default {
  name: "MyOrder",
  data() {
    return {
      activeName: "order",
      options: [
        {
          value: "6",
          label: "课程",
        },
        {
          value: "561",
          label: "SU模型",
        },
        {
          value: "1393",
          label: "3D模型",
        },
        {
          value: "7",
          label: "资料库",
        },
        {
          value: "1394",
          label: "方案文本",
        },
        {
          value: "1196",
          label: "PS素材",
        },
        {
          value: "2205",
          label: "贴图",
        },
      ],
      pickerOptions: {
        shortcuts: [
          {
            text: "最近一周",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近一个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近三个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
      reCodeList: [],
      reCodeList1: [],
      paramsData: {
        token: getToken(),
        page: "1",
        fid: "6",
        dateTime: "",
        start_time: "",
        end_time: "",
      },
      listTotal: 0,
    };
  },
  created() {
    this.getRecode();
  },
  methods: {
    //  下载
    download(item) {
      const fileUrl =
        "https://api.zhishew.com/v2/user/Download/doDown?id=" +
        item.pid +
        "&token=" +
        getToken();
      window.open(fileUrl, "_blank");
    },
    //  贴图下载
    downloadChartlet(item) {
			const downloadUrl =
				this.$ROOT_DIR +
				"/web/Picture/down?id=" +
				item.pid +
				"&token=" +
				getToken();
			let link = document.createElement("a");
			link.style.display = "none";
			link.href = downloadUrl;
			document.body.appendChild(link);
			link.click();
    },
    setPage(val) {
      this.paramsData.page = val;
    },
    restorePage() {
      this.paramsData.page = "1";
    },
    getRecode() {
      if (this.paramsData.dateTime && this.paramsData.dateTime.length > 0) {
        this.paramsData.start_time =
          Date.parse(this.paramsData.dateTime[0]) / 1000;
        this.paramsData.end_time =
          Date.parse(this.paramsData.dateTime[1]) / 1000;
      } else {
        this.paramsData.start_time = "";
        this.paramsData.end_time = "";
      }
      admireList(this.paramsData).then((res) => {
        this.reCodeList = res.data.data;
        this.listTotal = res.data.total;
				console.log( this.reCodeList);
      });
    },
    disCountList() {
      disCountList({ token: getToken(), page: this.paramsData.page }).then(
        (res) => {
          this.reCodeList1 = res.data.data;
          this.listTotal = res.data.total;
        }
      );
    },
    handleClick() {
      this.restorePage();
      if (this.activeName == "order") {
        this.getRecode();
      } else {
        this.disCountList();
      }
    },
    comment(data) {
      this.$commentPop({
        id: data.id,
        picture: data.img,
        workTitle: data.title,
        comment: data.comment,
        tate: data.socre,
        pid: data.pid,
        work_price: data.work_price,
      });
    },
  },
  watch: {
    paramsData: {
      handler: function () {
        if (this.activeName == "order") {
          this.getRecode();
        } else {
          this.disCountList();
        }
      },
      deep: true,
    },
  },
};
</script>

<style scoped>
.pasBox {
  flex: 1;
  position: relative;
}
.pas {
  position: absolute;
  left: 0;
  right: 0;
  bottom: -0.3rem;
}
.itemBut {
  width: 0.96rem;
  height: 0.3rem;
  line-height: 0.3rem;
  border: 1px solid #333333;
  border-radius: 0.04rem;
  text-align: center;
  font-size: 0.14rem;
  color: #333333;
  margin: 0 auto;
  cursor: pointer;
}
.itemInfo {
  height: 1.2rem;
  align-items: center;
  display: flex;
  padding-left: 0.2rem;
}

.itemImgCourse {
  width: 1.5rem;
  height: 0.9rem;
  border-radius: 0.04rem;
  overflow: hidden;
  margin-right: 0.2rem;
}
.itemImgCourse img {
  width: 1.5rem;
  height: 0.9rem;
}
.itemImgSu {
  width: 1.2rem;
  height: 0.9rem;
  border-radius: 0.04rem;
  overflow: hidden;
  margin-right: 0.2rem;
}
.itemImgSu img {
  width: 1.2rem;
  height: 0.9rem;
}
.itemImgMaterial {
  width: 0.9rem;
  height: 0.9rem;
  border-radius: 0.04rem;
  overflow: hidden;
  margin-right: 0.2rem;
}
.itemImgMaterial img {
  width: 0.9rem;
  height: 0.9rem;
}
.orderItem {
  border: 1px solid #dfdffe;
  margin-bottom: 0.2rem;
}
.orderItem1 {
  border: 1px solid #dfdffe;
  margin-bottom: 0.2rem;
  width: 4.68rem;
}
.orderItem1:nth-child(2n + 0) {
  margin-left: 0.2rem;
}
.orderList1 {
  display: flex;
  flex-wrap: wrap;
  /* justify-content: space-between; */
}
.itemHead {
  display: flex;
  align-items: center;
  background: #f9f9ff;
  height: 0.4rem;
}
.orderListBox {
  margin-top: 0.2rem;
}
.myOrderBox {
  background: #ffffff;
  border-radius: 0.04rem;
  padding: 0.2rem 0.3rem;
}
::v-deep .el-date-editor .el-range-separator {
  line-height: 0.32rem;
}
::v-deep .el-date-editor .el-range-input {
  font-size: 0.14rem;
}
::v-deep .el-range-editor.el-input__inner {
  padding: 0 10px;
}
::v-deep .el-input__icon {
  line-height: 0.32rem;
}
::v-deep .el-input__inner {
  font-size: 0.14rem;
  height: 0.32rem;
  line-height: 0.32rem;
}
.selectType {
  width: 1.4rem;
  font-size: 0.14rem;
  margin-right: 0.2rem;
}
.orderHead {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  padding-bottom: 0.22rem;
  border-bottom: 0.02rem solid #eeeeee;
}

.seleteBox >>> .el-tabs__header {
  margin: 0;
}

.seleteBox >>> .el-tabs__item {
  height: 00.4rem;
  line-height: 00.4rem;
}

.seleteBox >>> .el-tabs__active-bar {
  background-color: #f46600;
  bottom: 1px;
}

.seleteBox >>> .el-tabs__item.is-active {
  color: #f46600;
}

.seleteBox >>> .el-tabs__item:hover {
  color: #f46600;
  cursor: pointer;
}

.seleteBox {
  display: flex;
  align-items: center;
  justify-content: space-between;
  overflow: hidden;
  position: relative;
  line-height: 0.6rem;
  height: 0.6rem;
  background: #ffffff;
}

.inputBox {
  display: flex;
  justify-content: center;
  align-items: center;
}
.itemP {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 3rem;
}

.itemP1 {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 2.7rem;
}
</style>